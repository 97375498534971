<template>
  <section>
    <div class="grid md:grid-9-3 ">
      <div class=" h-65vh position-relative">

        <transition name="fade" v-for="(style,i) in styles">
          <div style="background-size: cover; background-position: center center; overflow: hidden; position: absolute; z-index:1; top:0" :style="{backgroundImage:'url('+orgamaxImage(style)+')'}"  v-if="style.id===selected.id" class="w-100p h-100p"></div>
        </transition>
      </div>
      <div class="m-l-2 p-3 d-flex f-column md:h-65vh">
        <selection-header :steps="6" :current-step="3" :back-to="'/exterior'"/>
        <total-runner :step="3" class="f-1"/>
        <bottom-nav to="/modules" back-to="/exterior"/>
      </div>
    </div>
    <tab-shop :item="selected" :hide-price="true" v-if="styles.length>0"  selected-tab="configuration" :multi-items="{configuration:styles}" @update="selected = $event.item">
      Create your interior
    </tab-shop>
  </section>

</template>

<script>
import api from "@/api";
import selectionHeader from "@/components/selectionHeader";
import TabShop from "@/components/TabShop"
import totalRunner from "@/components/totalRunner"
import {orgamaxImage} from "@/composables/orgamax";
import BottomNav from "@/components/BottomNav";

export default {
  name: "Interior",
  components:{selectionHeader, TabShop, totalRunner, BottomNav},
  data: () => ({
    styles: [],
    active:0,
    boatModel:{},
    selected: null
  }),
  async mounted() {
    this.styles = this.$store.getters.interior;
    // boat model
    this.boatModel = this.$store.state.boatModel
    // coming back?
    if(typeof this.$store.state.interior.id !== 'undefined'){
      this.selected = this.styles.find(s => s.id === this.$store.state.interior.id)
    } else {
      this.selected = this.styles[0];
    }
  },
  watch:{
    selected(newVal){
      if(newVal){
        this.$store.commit('setInterior', newVal)
      }
    }
  },
  methods: {
    orgamaxImage,
    price(modifier){
      return this.boatModel.price / 100 * modifier;
    },
    apply(module){
      this.$store.commit('setInterior', module);
      this.selected = module;
    }
  }
}
</script>

<style scoped>

</style>